<template>
    <span>
        <span class="hide-on-print">
            <v-dialog v-model="dialog" max-width="600">
                <v-card>
                    <v-card-text class="pa-5 d-flex flex-column align-center justify-center" v-if="!downloaded">
                        <h1 class="text-center my-2">Votre rapport devrait être prêt dans quelques instants</h1>
                        <h1 class="text-center my-2">veuillez patienter...</h1>
                        <v-progress-linear class="my-10" indeterminate color="primary"></v-progress-linear>
                        <h3 class="text-center">Si le rapport ne s'affiche pas, veuillez cliquer sur le bouton ci-dessous</h3>
                        <v-btn color="primary" @click="print">Télécharger</v-btn>
                    </v-card-text>
                    <v-card-text class="pa-5 d-flex flex-column align-center justify-center" v-else>
                        <h1 class="text-center my-2">Votre rapport est pret !</h1>
                        <h3 class="text-center">Pour retelecharger le raport, veuillez cliquer sur le bouton ci-dessous</h3>
                        <v-btn color="primary" outlined @click="print">Télécharger</v-btn>
                        <h3 class="text-center mt-5">Pour revenir au formulaire, veuillez cliquer sur le bouton ci-dessous</h3>
                        <v-btn color="indigo" outlined @click="goBackToForm">Retourner au formulaire</v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </span>
        <div v-if="form" class="ma-12 show-on-print">
            <h2 class="text-center" v-text="form.title"></h2>
            <h6 class="text-center mb-10" v-text="'Raport généré par Smile&Survey Copyright '+new Date().getFullYear()+' LoesusPedagogia'"></h6>
            <span class="d-flex flex-column align-center justify-center">
                <h5 v-text="'Nombre de sections : ' + form.sections.length"></h5>
                <h5 v-text="'Nombre de questions : ' + questionsAmount"></h5>
                <div class="my-3" style="width: 10%;">
                    <v-divider></v-divider>
                </div>
                <h5 v-text="'Nombre de questions obligatoires : ' + requiredQuestionsAmount"></h5>
                <h5 v-text="'Nombre de questions facultatives : ' + (questionsAmount - requiredQuestionsAmount)"></h5>
                <div class="my-3" style="width: 10%;">
                    <v-divider></v-divider>
                </div>
                <h5 v-text="'Nombre de réponses enregistrées : ' + responses.length"></h5>
                <div class="my-3" style="width: 10%;">
                    <v-divider></v-divider>
                </div>
                <h5 v-text="'Date de génération du raport : ' + new Date().toLocaleDateString()"></h5>
                <h5 v-text="'Heure de génération du raport : ' + new Date().toLocaleTimeString()"></h5>
            </span>
            <div style="break-after: page;">
                <div class="hide-on-print my-12" style="width : 100%; height:5px; border-bottom: 5px black solid;"></div>
            </div>
            <h2 class="mt-12 text-center">Questions :</h2>
            <div class="d-flex flex-column align-start justify-left mt-5">
                <span v-for="section, sectionIndex in form.sections" class="mb-5">
                    <h3 v-text="alphabet[sectionIndex] + '/ ' + section.name"></h3>
                    <span v-for="question, questionIndex in section.questions">
                        <h4 class="ml-6" >
                            <span v-text="(questionIndex+1) + '. ' + question.title + ' ('+question.type+')'"></span>
                            <span v-if="question.required || question.type == 'slider'" class="accent--text"> *</span>
                        </h4>
                        <ul v-if="question.type == 'listeUnique' || question.type == 'listeMultiple'">
                            <li class="ml-12" v-for="choice in question.choices" v-text="choice"></li>
                        </ul>
                    </span>
                </span>
            </div>
            <div style="break-after: page;">
                <div class="hide-on-print my-12" style="width : 100%; height:5px; border-bottom: 5px black solid;"></div>
            </div>

            <h2 class="mt-12 text-center">Rapport de satisfaction :</h2>
            <v-card flat>
                <v-card-text v-if="statsCanBeComputed">
                    <div class="my-5" v-for="section, sectionIndex in form.sections" v-if="totalStats[sectionIndex].amount > 0">
                        <v-divider></v-divider>
                        <h1 class="text-center white--text py-3 primary font-weight-bold" v-text="section.name"></h1>
                        <v-divider></v-divider>
                        <span class="d-flex flex-row align-center justify-start">
                            <div style="width : 100%">
                                <span v-for="question, questionIndex in section.questions" v-if="question.type == 'slider'" class="my-5 d-flex flex-column align-center justify-center">
                                    <h3 v-text="question.title"></h3>
                                    <span v-if="question.type == 'slider'" class="d-flex align-center justify-start">
                                        <VueApexCharts :options="donutsChartOptions" :series="stats[sectionIndex][questionIndex].series"></VueApexCharts>
                                        <v-icon class="ml-5 mr-6" x-large>mdi-arrow-right</v-icon>
                                        <div style="width: 250px;">
                                            <img v-if="question.type == 'slider' && Math.round(stats[sectionIndex][questionIndex].mean) == 0" class="ml-12" src="../assets/images/smiley/smiley R.svg" style="width: 100px;"/>
                                            <img v-else-if="question.type == 'slider' && Math.round(stats[sectionIndex][questionIndex].mean) == 1" class="ml-12" src="../assets/images/smiley/smiley O.svg" style="width: 100px;"/>
                                            <img v-else-if="question.type == 'slider' && Math.round(stats[sectionIndex][questionIndex].mean) == 2" class="ml-12" src="../assets/images/smiley/smiley J.svg" style="width: 100px;"/>
                                            <img v-else-if="question.type == 'slider' && Math.round(stats[sectionIndex][questionIndex].mean) == 3" class="ml-12" src="../assets/images/smiley/smiley VJ.svg" style="width: 100px;"/>
                                            <img v-else-if="question.type == 'slider' && Math.round(stats[sectionIndex][questionIndex].mean) == 4" class="ml-12" src="../assets/images/smiley/smiley VV.svg" style="width: 100px;"/>
                                        </div>
                                    </span>
                                </span>
                                <v-divider></v-divider>
                                <span class="my-5 d-flex flex-column align-center justify-center">
                                    <h2 v-text="section.name + ' (Total)'" class="accent--text"></h2>
                                    <span class="d-flex align-center justify-start">
                                        <VueApexCharts :options="donutsChartOptions" :series="totalStats[sectionIndex].series"></VueApexCharts>
                                        <v-icon class="ml-5 mr-6" x-large>mdi-arrow-right</v-icon>
                                        <div style="width: 250px;">
                                            <img v-if="Math.round(totalStats[sectionIndex].mean) == 0" class="ml-12" src="../assets/images/smiley/smiley R.svg" style="width: 100px;"/>
                                            <img v-else-if="Math.round(totalStats[sectionIndex].mean) == 1" class="ml-12" src="../assets/images/smiley/smiley O.svg" style="width: 100px;"/>
                                            <img v-else-if="Math.round(totalStats[sectionIndex].mean) == 2" class="ml-12" src="../assets/images/smiley/smiley J.svg" style="width: 100px;"/>
                                            <img v-else-if="Math.round(totalStats[sectionIndex].mean) == 3" class="ml-12" src="../assets/images/smiley/smiley VJ.svg" style="width: 100px;"/>
                                            <img v-else-if="Math.round(totalStats[sectionIndex].mean) == 4" class="ml-12" src="../assets/images/smiley/smiley VV.svg" style="width: 100px;"/>
                                        </div>
                                    </span>
                                </span>
                            </div>
                        </span>
                    </div>
                    <div class="my-5">
                        <v-divider></v-divider>
                        <h1 class="text-center white--text py-3 primary font-weight-bold">TOTAL</h1>
                        <v-divider></v-divider>
                        <span class="d-flex flex-row align-center justify-start">
                            <div style="width : 100%">
                                <span class="my-5 d-flex flex-column align-center justify-center">
                                    <span class="d-flex align-center justify-start">
                                        <VueApexCharts :options="donutsChartOptions" :series="totalStats.total.series"></VueApexCharts>
                                        <v-icon class="ml-5 mr-6" x-large>mdi-arrow-right</v-icon>
                                        <div style="width: 250px;">
                                            <img v-if="Math.round(totalStats.total.mean) == 0" class="ml-12" src="../assets/images/smiley/smiley R.svg" style="width: 100px;"/>
                                            <img v-else-if="Math.round(totalStats.total.mean) == 1" class="ml-12" src="../assets/images/smiley/smiley O.svg" style="width: 100px;"/>
                                            <img v-else-if="Math.round(totalStats.total.mean) == 2" class="ml-12" src="../assets/images/smiley/smiley J.svg" style="width: 100px;"/>
                                            <img v-else-if="Math.round(totalStats.total.mean) == 3" class="ml-12" src="../assets/images/smiley/smiley VJ.svg" style="width: 100px;"/>
                                            <img v-else-if="Math.round(totalStats.total.mean) == 4" class="ml-12" src="../assets/images/smiley/smiley VV.svg" style="width: 100px;"/>
                                        </div>
                                    </span>
                                </span>
                            </div>
                        </span>
                    </div>
                </v-card-text>
                <v-card-text v-else>
                    données introuvables
                </v-card-text>
            </v-card>

            <div style="break-after: page;">
                <div class="hide-on-print my-12" style="width : 100%; height:5px; border-bottom: 5px black solid;"></div>
            </div>


            <h2 class="mt-12 text-center">Rapport par question :</h2>
            <span flat v-if="responses">
                <div v-for="sectionIndex in Object.keys(questionResponses)">
                    <div class="mt-5" v-for="questionIndex in Object.keys(questionResponses[sectionIndex])" v-if="questionResponses[sectionIndex][questionIndex].type != 'slider'">
                        <h3 v-text="questionResponses[sectionIndex][questionIndex].section + ' : ' + questionResponses[sectionIndex][questionIndex].question"></h3>
                        <VueApexCharts v-if="questionResponses[sectionIndex][questionIndex].type == 'listeUnique' || questionResponses[sectionIndex][questionIndex].type == 'listeMultiple'" :options="barChartOptions" :series="questionResponses[sectionIndex][questionIndex].series"></VueApexCharts>
                        <h4 class="ml-5" v-else v-for="response of questionResponses[sectionIndex][questionIndex].responses" v-text="response"></h4>
                    </div>
                </div>
            </span>
            <span v-for="response, responseIndex in responses ">
                <h1></h1>
                <h1 style="break-before: page; opacity: 0; font-size: 0.01px;">break</h1>
                <h2 class="mt-12 text-center" v-text="'Réponse n°' + (responseIndex + 1) + ' :'"></h2>
                <span v-for="section, sectionIndex in response.sections" class="mb-5">
                    <h3 v-text="alphabet[sectionIndex] + '/ ' + section.name"></h3>
                    <div class="mb-3" v-for="question, questionIndex in section.questions">
                        <h4 class="ml-6" >
                            <span v-text="(questionIndex+1) + '. ' + question.title + ' ('+question.type+')'"></span>
                            <span v-if="question.required || question.type == 'slider'" class="accent--text"> *</span>
                        </h4>
                        <img v-if="question.type == 'slider' && question.answer == 0" class="ml-12" src="../assets/images/smiley/smiley R.svg" style="width: 50px;"/>
                        <img v-else-if="question.type == 'slider' && question.answer == 1" class="ml-12" src="../assets/images/smiley/smiley O.svg" style="width: 50px;"/>
                        <img v-else-if="question.type == 'slider' && question.answer == 2" class="ml-12" src="../assets/images/smiley/smiley J.svg" style="width: 50px;"/>
                        <img v-else-if="question.type == 'slider' && question.answer == 3" class="ml-12" src="../assets/images/smiley/smiley VJ.svg" style="width: 50px;"/>
                        <img v-else-if="question.type == 'slider' && question.answer == 4" class="ml-12" src="../assets/images/smiley/smiley VV.svg" style="width: 50px;"/>
                        <ul v-else-if="question.type == 'listeUnique'">
                            <li class="ml-12" v-for="choice in question.choices">
                                <span v-text="choice" class="px-2 selectedChoice" v-if="question.answer == choice"></span>
                                <span v-text="choice" class="px-2 notSelectedChoice" v-else></span>
                            </li>
                        </ul>
                        <ul v-else-if="question.type == 'listeMultiple'">
                            <li class="ml-12" v-for="choice in question.choices">
                                <span v-text="choice" class="px-2 selectedChoice" v-if="question.answer.includes(choice)"></span>
                                <span v-text="choice" class="px-2 notSelectedChoice" v-else></span>
                            </li>
                        </ul>
                        <h5 class="ml-12" v-else v-text="question.answer"></h5>
                    </div>
                </span>
            </span>

            <div style="break-after: page;">
                <div class="hide-on-print my-12" style="width : 100%; height:5px; border-bottom: 5px black solid;"></div>
            </div>

            <span class="d-flex align-center justify-center" style="margin-top: 500px;">
                <a :href="generatUrl()">
                    <v-btn color="primary" outlined>Acceder a la version en ligne</v-btn>
                </a>
            </span>
        </div>
    </span>
    
</template>

<style scoped>
    .selectedChoice {
        color : blue;
        border: solid blue 1px;
    }
    .notSelectedChoice {
        color : orangered;
        text-decoration: line-through;
    }
</style>

<script>
import Form from '@/classes/Form.js'
import Response from '@/classes/Response.js'

import VueApexCharts from 'vue-apexcharts'

import SatisfactionTab from '@/components/responses/SatisfactionTab.vue'

export default {
    name: "Print",
    props : ['loggedin'],
    components : {
        SatisfactionTab,
        VueApexCharts,
    },
    data() {
        return {
            dialog: true,
            downloaded: false,
            unsub : [],
            smileyOrder: ['R', 'O', 'J', 'VJ', 'VV'],
            alphabet : 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            form : null,
            responses : null,
            barChartOptions: {
                chart: {
                    type: 'bar',
                    animations: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true
                    },
                },
                stroke: {
                    width: 0,
                },
                colors : ['#006533'],
                states: {
                    active: {
                        filter: {
                            type: 'none'
                        }
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    enabled: false
                },
                dataLabels: {
                    position: 'center',
                },
                responsive: [{
                    breakpoint: Infinity,
                    options: {
                        chart: {
                            height: '250',
                            width: '500'
                        },
                    }
                }]
            },
            donutsChartOptions: {
                chart: {
                    type: 'donut',
                },
                colors: ['#CB4F24','#E7B142', '#F7EA3A','#A2C037', '#6AA842'],
                labels: ['4', '3', '2', '1', '0'],
                stroke: {
                    width: 0,
                },
                states: {
                    active: {
                        filter: {
                            type: 'none'
                        }
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    enabled: false
                },
                dataLabels: {
                    position: 'center',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '50%',
                        },
                        expandOnClick: false
                    },
                },
                responsive: [{
                    breakpoint: Infinity,
                    options: {
                        chart: {
                            height: '250',
                            width: '250',
                        },
                    }
                }]
            },
        }
    },
    mounted() {
        this.print()
    },
    watch : {
        '$route' : {
            handler : function(){
                this.unsub.push(Form.listenById(this.$route.params.id, (form) => {
                    this.form = form
                    this.$forceUpdate()
                }))
                this.unsub.push(Response.listenByForm(this.$route.params.id, (responses) => {
                    this.responses = responses
                    this.$forceUpdate()
                }))
            },
            immediate : true
        }
    },
    computed : {
        questionResponses(){
            let questionResponses = {}
            for(let response of this.responses){
                for(let [sectionIndex, section] of response.sections.entries()){
                    if(!questionResponses[sectionIndex]){
                        questionResponses[sectionIndex] = {}
                    }
                    for(let [questionIndex, question] of section.questions.entries()){
                        if(!questionResponses[sectionIndex][questionIndex]){
                            questionResponses[sectionIndex][questionIndex] = {
                                section : section.name,
                                question : question.title,
                                responses : [],
                                type : question.type,
                                series : [{data: []}]
                            }
                        }
                        if(question.type != 'slider'){
                            questionResponses[sectionIndex][questionIndex].responses.push(response.sections[sectionIndex].questions[questionIndex].answer)
                        }
                        if(question.type == 'listeUnique'){
                            let index = questionResponses[sectionIndex][questionIndex].series[0].data.findIndex((data) => data.x == response.sections[sectionIndex].questions[questionIndex].answer)
                            if(index == -1){
                                questionResponses[sectionIndex][questionIndex].series[0].data.push({
                                    x : response.sections[sectionIndex].questions[questionIndex].answer,
                                    y : 1
                                })
                            } else {
                                questionResponses[sectionIndex][questionIndex].series[0].data[index].y++
                            }
                        }
                        if(question.type == 'listeMultiple'){
                            for(let answer of response.sections[sectionIndex].questions[questionIndex].answer){
                                let index = questionResponses[sectionIndex][questionIndex].series[0].data.findIndex((data) => data.x == answer)
                                if(index == -1){
                                    questionResponses[sectionIndex][questionIndex].series[0].data.push({
                                        x : answer,
                                        y : 1
                                    })
                                } else {
                                    questionResponses[sectionIndex][questionIndex].series[0].data[index].y++
                                }
                            }
                        }
                    }
                }
            }
            return questionResponses
        },
        questionsAmount() {
            let amount = 0
            this.form.sections.forEach(section => {
                amount += section.questions.length
            })
            return amount
        },
        requiredQuestionsAmount(){
            let amount = 0
            this.form.sections.forEach(section => {
                section.questions.forEach(question => {
                    if(question.required || question.type == 'slider') {
                        amount++
                    }
                })
            })
            return amount
        },
        statsCanBeComputed(){
            for(let response of this.responses){
                for(let section of response.sections){
                    for(let question of section.questions){
                        if(question.type == 'slider'){
                            return true
                        }
                    }
                }
            }
            return false
        },
        stats(){
            let stats = []
            for(let response of this.responses){
                for(let [sectionIndex, section] of response.sections.entries()){
                    if(!stats[sectionIndex]){
                        stats[sectionIndex] = []
                    }
                    for(let [questionIndex, question] of section.questions.entries()){
                        if(!stats[sectionIndex][questionIndex]){
                            stats[sectionIndex][questionIndex] = {
                                series : [0,0,0,0,0],
                                mean : 0,
                            }
                        }
                        if(question.type == 'slider'){
                            stats[sectionIndex][questionIndex].series[question.answer] += 1
                            let total = 0
                            let amount = 0
                            for(let [index, value] of stats[sectionIndex][questionIndex].series.entries()){
                                total += value * index
                                amount += value
                            }
                            if(amount > 0){
                                stats[sectionIndex][questionIndex].mean = total / amount
                            }
                        }
                    }
                }
            }
            return stats
        },
        totalStats(){
            let totalStats = {
                sections : [],
                total : {
                    series : [0,0,0,0,0],
                    mean : 0,
                }
            }
            let mainTotal = 0
            let mainAmount = 0
            for(let response of this.responses){
                for(let [sectionIndex, section] of response.sections.entries()){
                    if(!totalStats[sectionIndex]){
                        totalStats[sectionIndex] = {
                            series : [0,0,0,0,0],
                            mean : 0,
                            total : 0,
                            amount : 0,
                        }
                    }
                    for(let question of section.questions){
                        if(question.type == 'slider'){
                            totalStats[sectionIndex].series[question.answer] += 1
                            totalStats.total.series[question.answer] += 1
                            mainAmount = 0
                            mainTotal = 0
                            totalStats[sectionIndex].amount = 0
                            totalStats[sectionIndex].total = 0
                            for(let [index, value] of totalStats[sectionIndex].series.entries()){
                                totalStats[sectionIndex].total += value * index
                                mainTotal += value * index
                                totalStats[sectionIndex].amount += value
                                mainAmount += value
                            }
                        }
                    }
                    if(totalStats[sectionIndex].amount > 0){
                        totalStats[sectionIndex].mean = totalStats[sectionIndex].total / totalStats[sectionIndex].amount
                    }
                }
                if(mainAmount > 0){
                    totalStats.total.mean = mainTotal / mainAmount
                }
            }
            return totalStats
        }
    },
    methods: {
        print() {
            setTimeout(() => {
                this.dialog = false
                setTimeout(() => {
                    window.print()
                    setTimeout(() => {
                        this.downloaded = true
                        this.dialog = true
                    }, 1000)
                }, 1000)
            }, 1000)
        },
        goBackToForm() {
            this.$router.push('/responses/' + this.$route.params.id)
        },
        generatUrl(){
            return window.location.origin + '/responses/' + this.$route.params.id
        },
    },
}
</script>
